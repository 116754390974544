import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/telesalud/Hero"
import TeleSalud from "../components/telesalud/Telesalud"
import Beneficios from "../components/telesalud/Beneficios"
import TeleConsulta from "../components/telesalud/Teleconsulta2";

const IndexPage = (props) => (
  <Layout>
    <Seo title="TeleSalud" />
    <Hero/>
    <TeleSalud/>
    <Beneficios/>
    <TeleConsulta/>
  </Layout>
);

export default IndexPage;

