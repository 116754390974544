import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Beneficios = () => {
    return (
    <section id = "telesan-hero2">
       <div className = "telesan-row">
           
                <div className = "columns grafico">
                    <StaticImage
                        src = "../../assets/telesalud/telesan_telesalud_beneficios.JPG"
                        width = {700}
                        alt= "Telesan teleconsulta"
                    />]
                </div>
                <div className = "texto">
                    <h1>Beneficios</h1>
                    <p>
                        <ul>
                            <li>Llevar servicios de especialidades a zonas remotas.
                                </li>
                            <li>Reducir la referencia de pacientes y el	costo derivado de estas.
                            </li>
                            <li>Crear redes y facilitar la comunicación entre profesionales de la salud a nivel educativo, 
                                asistencial y de investigación.
                            </li>
                            <li> Fortalecer	el seguimiento de pacientes y reduce los tiempos de espera.
                            </li>

                        </ul>
                        <div className = "circles">
                            <FontAwesomeIcon icon={faCircle} />
                            <FontAwesomeIcon icon={faCircle} />
                            <FontAwesomeIcon icon={faCircle} />
                        </div>
                    </p>
                 </div>
        </div>
    </section>
    )
}

export default Beneficios