import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

export default function CustomizedSteppers() {
 
  return (
    <section id="teleconsulta">
       <h1>¿Cómo funciona la teleconsulta?</h1>
       
        <div className='progress'>
            <div className ='progress_inner'>
                <div className ='progress_inner__step'>
                    <label for='step-1'>Atiende</label>
                </div>
                <div className ='progress_inner__step'>
                    <label for='step-2'>Ingresa</label>
                </div>
                <div className ='progress_inner__step'>
                    <label for='step-3'>Analiza</label>
                </div>
                <div className ='progress_inner__step'>
                    <label for='step-4'>Responde</label>
                </div>
                <div className ='progress_inner__step'>
                    <label for='step-5'>Repite</label>
                </div>
   
                <input id='step-1' name='step' type='radio'/>
                <input id='step-2' name='step' type='radio'/>
                 <input id='step-3' name='step' type='radio'/>
                <input id='step-4' name='step' type='radio'/>
                <input id='step-5' name='step' type='radio'/>
   
                <div className ='progress_inner__bar'></div>
                <div className ='progress_inner__bar--set'></div>

                <div className ='progress_inner__tabs'>
                    <div className ='tab tab-0'>
                        <h1>Atiende</h1>
                        <p>El médico atiende la consulta de un paciente.</p>
                        <StaticImage
                            src = "../../assets/telesan/telesan_teleconsulta12.png"
                            width = {64}
                            alt= "Telesan teleconsulta paso 1"
                         />
                    </div>
                    <div className ='tab tab-1'>
                        <h1>Ingresa</h1>
                        <p>
                        El médico ingresa a la plataforma TeleSAN para referir al paciente a un servicio especializado
                        </p>
                        <StaticImage
                            src = "../../assets/telesan/telesan_teleconsulta22.png"
                            width = {64}
                            alt= "Telesan teleconsulta paso 2"
                         />
                    </div>
                    <div className ='tab tab-2'>
                        <h1>Analiza</h1>
                        <p>
                        El médico especialista analiza el caso basado en lo enviado por el médico en la teleconsulta.
                        </p>
                        <StaticImage
                            src = "../../assets/telesan/telesan_teleconsulta32.png"
                            width = {64}
                            alt= "Telesan teleconsulta paso 3"
                         />
                    </div>
                    <div className ='tab tab-3'>
                        <h1>Responde</h1>
                        <p>
                        El médico especialista da la respuesta a la teleconsulta.
                        </p>
                        <StaticImage
                            src = "../../assets/telesan/telesan_teleconsulta42.png"
                            width = {64}
                            alt= "Telesan teleconsulta paso 4"
                         />
                    </div>
                    <div className ='tab tab-4'>
                        <h1>Repite</h1>
                        <p>Repite el proceso de ser necesario.</p>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
  );
}
