import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Telesan = () => {
    return (
    <section id = "telesan-hero1">
        <div className = "telesan-row">
          
            <div className = "texto">
                <h1>¿Qué es TeleSalud?</h1>
                <p>
                    Consiste en la prestación de servicios de salud utilizando 
                    las tecnologías de la información y la comunicación, 
                    especialmente donde la distancia es una barrera para recibir atención de salud.
                    <em>(OPS/OMS)</em>
                </p>
                <div className = "circles">
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                </div>
            </div>
            <div className = "columns grafico">
                <StaticImage
                    src = "../../assets/telesalud/telesan_telesaludhonduras.jpg"
                    width = {700}
                    alt= "Telesan teleconsulta"
                />
            </div>
        </div>
    </section>
    )
}

export default Telesan